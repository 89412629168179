import { motion, useScroll, useTransform } from "framer-motion";

import Media from "@/components/shared/Media";
import useHasMounted from "@/hooks/useHasMounted";
import useWindowSize from "@/hooks/useWindowSize";

const PageHeader = ({ header, title, modal, refContainer }) => {
  const { media, subhead, type } = header || {};
  console.log({ header, modal, refContainer });

  const { scrollY } = useScroll({ container: refContainer });

  const { windowH } = useWindowSize();

  const opacity = useTransform(
    scrollY,
    [0, windowH / 3, windowH + 1],
    [1, 0, 0],
  );

  const hasMounted = useHasMounted();

  return !hasMounted ? null : (
    <>
      <section className="PageHeader" data-type={type}>
        {type !== "none" && (
          <figure>
            <figcaption>
              <h2>{title}</h2>
            </figcaption>
            {type !== "default" && (
              <motion.div className="wrapper" style={{ opacity }}>
                <Media media={media} />
              </motion.div>
            )}
          </figure>
        )}
      </section>

      <style jsx global>{`
        .PageHeader figcaption {
          width: 100%;
          height: 50vh;
          position: sticky;
          top: calc((100vh - 50vh) / 2);
          z-index: 1;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: var(--fs-xxl);
        }

        .PageHeader .wrapper {
          width: 100%;
          height: 100vh;
          position: fixed;
          top: 0;
          left: 0;
          z-index: 0;
          pointer-events: none !important;
        }

        .PageHeader:not([data-type="default"]) {
          height: 100vh;

          padding-top: calc((100vh - 50vh) / 2);
          margin-bottom: calc((100vh - 50vh) / -2);
        }

        .PageHeader[data-type="none"] {
          height: var(--dist-md);
          margin: unset;
          padding: unset;
        }

        .PageHeader[data-type="passepartout"] .wrapper {
          padding: var(--h-header) var(--dist-sm) var(--dist-sm) var(--dist-sm);
        }

        .PageHeader .Media {
          width: 100%;
          height: 100%;
          pointer-events: none !important;
        }

        .PageHeader .Media * {
          pointer-events: none !important;
        }
      `}</style>
    </>
  );
};

export default PageHeader;
