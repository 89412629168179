import { motion } from "framer-motion";
import { useRouter } from "next/router";

import Map from "@/components/shared/Map";

const HomeMap = ({
  projects,
  selectedProject,
  setSelectedProject,
  focusedProject,
  setFocusedProject,
}) => {
  const router = useRouter();

  const { mode } = router?.query || {};

  return (
    <>
      <motion.article
        className="HomeMap"
        initial={{ opacity: 0 }}
        animate={{
          opacity: mode !== "index" ? 1 : 0.05,
        }}
        exit={{ opacity: 0 }}
        // transition={{ duration: 1, type: "linear" }}
      >
        <Map
          {...{
            projects,
            selectedProject,
            setSelectedProject,
            focusedProject,
            setFocusedProject,
            mode,
          }}
        />
      </motion.article>

      <style jsx global>{`
        .HomeMap {
          position: fixed;
          inset: 0;
        }

        .HomeMap :is(.mapboxgl-control-container, canvas) {
          opacity: 0;
        }

        .HomeMap:has(ul:has(li[data-id="1"]:hover)) li[data-id="1"] {
          color: red;
        }

        .HomeMap:has(li[data-id="1"]:hover) li[data-id="1"] {
          color: red;
        }
      `}</style>
    </>
  );
};

export default HomeMap;
