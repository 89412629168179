import { animate,motion, useMotionValue, useTransform } from "framer-motion";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";

const HomeIndex = ({ projects, selectedProject, setSelectedProject }) => {
  const router = useRouter();

  const { mode } = router?.query || {};

  const [counter, setCounter] = useState(0);

  useEffect(() => {
    const controls = animate(
      mode !== "index" ? 100 : 0,
      mode === "index" ? 100 : 0,
      {
        duration: 0.4,
        onUpdate(value) {
          setCounter(value);
        },
      },
    );

    return () => controls?.stop();
  }, [mode]);

  return (
    <>
      <motion.article
        className="HomeIndex"
        initial={{ opacity: 0 }}
        animate={{
          opacity: mode === "index" ? 1 : 0.05,
        }}
        exit={{ opacity: 0 }}
        // transition={{ duration: 1, type: "linear" }}
        style={{
          "--h": counter + "%",
          pointerEvents: mode === "index" ? undefined : "none",
        }}
      >
        <motion.section
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ delay: 0 * 0.1 }}
        >
          <h3>Bauten</h3>
          <ul>
            {projects?.map((project, i) => (
              <li
                key={i}
                data-id={i}
                onClick={() => setSelectedProject(project)}
              >
                {project?.title}
              </li>
            ))}
          </ul>
        </motion.section>
        <motion.section
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ delay: 0 * 0.1 }}
        >
          <h3>Architekt:innen</h3>
          <ul>
            {projects?.map(({ architects }, i) => (
              <li key={i} data-id={i}>
                {architects?.map(({ title }, i) => title)}
              </li>
            ))}
          </ul>
        </motion.section>
        <motion.section
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ delay: 0 * 0.1 }}
        >
          <h3>Jahr</h3>
          <ul>
            {projects?.map(({ year }, i) => (
              <li key={i}>{year}</li>
            ))}
          </ul>
        </motion.section>
        <motion.section
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ delay: 0 * 0.1 }}
        >
          <h3>Typology</h3>
          <ul>
            {projects?.map(({ types }, i) => (
              <li key={i}>{types?.map(({ name }) => name)}</li>
            ))}
          </ul>
        </motion.section>
        <motion.section
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ delay: 0 * 0.1 }}
        >
          <h3>Adresse</h3>
          <ul>
            {projects?.map(({ address }, i) => (
              <li key={i}>{address}</li>
            ))}
          </ul>
        </motion.section>
      </motion.article>

      <style jsx global>{`
        .HomeIndex {
          display: grid;
          grid-template-columns: repeat(5, 1fr);
          padding: var(--dist-sm);
          height: calc(100vh - var(--h-header));
        }

        .HomeIndex h3 {
          font-family: "Antique-Legacy";
          font-weigth: 450;
          font-size: calc(var(--fs-sm) / 1.2); /* Compensate for uppercase */
          line-height: var(--lh-sm);
          text-transform: uppercase;
          letter-spacing: calc((var(--fs-sm) / 1.2) * 0.12);
          margin-bottom: var(--dist-sm);
        }

        .HomeIndex:has(ul:has(li[data-id="1"]:hover)) li[data-id="1"] {
          color: red;
        }

        .HomeIndex:has(li[data-id="1"]:hover) li[data-id="1"] {
          color: red;
        }

        .HomeIndex section {
          position: relative;
          padding-left: var(--dist-xs);
          height: 100%;
        }

        .HomeIndex section::before {
          content: "";
          width: 1px;
          height: 100%;
          background: black;
          position: absolute;
          top: 0;
          left: 0px;
          mask-image: linear-gradient(
            to bottom,
            transparent 0%,
            transparent calc(100% - var(--h)),
            black calc(100% - var(--h) + 50% - var(--h) / 2),
            black 100%
          );
        }

        .HomeIndex section::after {
          content: "";
          width: 5px;
          height: 100%;
          background: linear-gradient(
            to top,
            black 0px,
            transparent 1.5px,
            transparent calc(100% - 1.5px),
            black 100%
          );
          position: absolute;
          top: 0;
          left: -2px;
          mask-image: linear-gradient(
            to bottom,
            transparent 0%,
            transparent calc(100% - var(--h)),
            black calc(100% - var(--h) + 50% - var(--h) / 2),
            black 100%
          );
        }
      `}</style>
    </>
  );
};

export default HomeIndex;
