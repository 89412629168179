import Head from "next/head";
import { groq } from "next-sanity";
import { useLiveQuery } from "next-sanity/preview";

import Home from "@/components/routes/home/Home";
import Page from "@/components/routes/page/Page";
import { readToken } from "@/lib/sanity.api";
import { getClient } from "@/lib/sanity.client";
import { refMedia } from "@/lib/sanity.queries";

export default function Route(props) {
  const { query, params, route, draftMode } = props;

  const [data, loading] = useLiveQuery(props?.data, query, params);

  console.log({ data, props, route });

  return (
    <>
      {route === "" ? (
        <Home {...(!data?._id ? data : { data })} />
      ) : (
        <Page {...(!data?._id ? data : { data })} />
      )}
    </>
  );
}

export const getServerSideProps = async (ctx) => {
  const { draftMode = false, params = {}, locale } = ctx;
  const client = getClient(draftMode ? readToken : undefined);

  const slug = ctx?.query?.slug?.[0] || "",
    sub = ctx?.query?.slug?.[1] || "";

  let query;

  switch (slug) {
    case "":
      query = [queries?.home];
      break;
    case "info":
      query = [queries?.page];
      break;
    case "aktuelles":
      query = [queries?.page];
      break;
    case "kontakt":
      query = [queries?.page];
      break;
    default:
      query = [queries?.page];
  }

  const [data] = await Promise.all([
    client.fetch(groq`{${query.filter(Boolean).join(",")}}`, {
      ...params,
      locale,
      slug,
      sub,
    }),
  ]);

  console.log({ ctx, data, query, slug });

  if (!data) {
    return {
      notFound: true,
    };
  }

  return {
    props: {
      route: slug,
      data,
      draftMode,
      query,
      params: { ...params, locale, slug, sub },
      token: draftMode ? readToken : "",
    },
  };
};

const queries = {
  home: `"projects": *[_type == "project"]|order(orderRank){..., preview{..., ${refMedia}}, architects[]->, types[]->}`,
  /*item -> dereferencing news page*/
  page: `"page": *[_type in ["page"] && slug.current == $slug][0]{..., header{..., media {..., ${refMedia}}}, content[]{..., item->{..., preview {..., ${refMedia}}}, content[]{..., ${refMedia}}}}`,
};
