import { groq } from "next-sanity";
import { useRef } from "react";
import useSWR from "swr";
import { Drawer } from "vaul";

import { getClient } from "@/lib/sanity.client";
import { refMedia } from "@/lib/sanity.queries";

import Page from "../page/Page";

const HomeOverlay = ({ selectedProject, setSelectedProject }) => {
  const { data, error, isLoading } = useSWR(
    groq`*[_type == "project" && _id == "${selectedProject?._id}"][0]{
      ..., content[]{..., item->{..., preview {..., ${refMedia}}}, content[]{..., ${refMedia}}}, architects[]->, types[]->
  }`,
    (query) => getClient(undefined).fetch(query),
  );

  const ref = useRef();

  console.log({ data });

  return (
    <>
      <article className="HomeOverlay">
        <Drawer.Root
          open={!!selectedProject}
          onClose={() => setSelectedProject(null)}
        >
          <Drawer.Portal>
            <Drawer.Content>
              <section
                className="HomeOverlayContent"
                onClick={() => setSelectedProject(null)}
                ref={ref}
              >
                <Page page={data} modal={true} refContainer={ref} />
              </section>
            </Drawer.Content>
            <Drawer.Overlay className="HomeOverlayBackground" />
          </Drawer.Portal>
        </Drawer.Root>
      </article>
      <style jsx global>{`
        [vaul-drawer] {
          position: fixed;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          z-index: 1;
        }

        .HomeOverlayContent {
          display: block;
          width: 100%;
          height: 100%;
          background: white;
          overflow: auto;
          position: relative;
          -ms-overflow-style: none;
          scrollbar-width: none;
        }

        .HomeOverlayBackground {
          position: fixed;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          backdrop-filter: blur(1px);
          background: hsla(0 0% 100% / 0.9);
          z-index: 0;
          pointer-events: none;
        }
      `}</style>
    </>
  );
};

export default HomeOverlay;
