const Pin = ({ index, active, project }) => {
  return (
    <>
      <div className="Pin" data-active={active} data-index={index.toString()}>
        <div className="PinInner">{project?.title}</div>
      </div>
      <style jsx global>{`
        .Pin {
          position: relative;
          display: block;
          width: auto;
          height: var(--size-sm);
          line-height: var(--size-sm);
          white-space: nowrap;
          padding: 0 1em;
          background: var(--color-black);
          border-radius: 2px;
          font-size: calc(var(--fs-sm) / 1.2); /* Compensate for uppercase */
          text-transform: uppercase;
          letter-spacing: 0.12em;
          color: var(--color-white);
        }

        .Pin:not([data-active="true"]) {
          opacity: 0.05;
        }

        .Pin::before {
          position: absolute;
          top: 0;
          right: calc(100% + 1em);
          content: attr(data-index);
          color: var(--color-black);
        }
      `}</style>
    </>
  );
};
export default Pin;
