import MuxPlayer from "@mux/mux-player-react";
import { motion } from "framer-motion";
import { useCallback, useEffect, useRef, useState } from "react";

import useHasMounted from "@/hooks/useHasMounted";

const Video = ({
  media,
  className,
  children,
  onClick,
  active,
  style,
  ...props
}) => {
  const [status, setStatus] = useState({ canPlay: false });

  const ref = useRef();

  const hasMounted = useHasMounted();

  useEffect(() => {
    status?.canPlay && ref?.current?.play();
  }, [hasMounted, status?.canPlay]);

  const { asset } = media?.video || {},
    { data, playbackId, thumbTime = 0 } = asset || {};

  if (!data) return null;
  if (!data?.aspect_ratio) return null;

  const [width, height] = data?.aspect_ratio?.split(":"),
    ratio = parseInt(width) / parseInt(height);
  return !hasMounted ? null : (
    <>
      <motion.div
        className={["Video", "Media", className].join(" ")}
        data-orientation={ratio < 1 ? "portrait" : "landscape"}
        data-ratio={ratio}
        style={{ ...style, "--ratio": ratio }}
        onViewportEnter={() => {
          status?.canPlay && ref?.current?.play();
        }}
        onViewportLeave={() => {
          ref?.current?.pause();
        }}
        viewport={{ amount: 0.33 }}
        {...props}
      >
        <div
          onClick={(e) =>
            e.nativeEvent.layerY < e.currentTarget.clientHeight - 44 &&
            onClick?.(e)
          }
        >
          <MuxPlayer
            disableCookies
            ref={ref}
            autoplay="muted"
            loop
            nohotkeys
            muted="true"
            playsInline
            streamType="on-demand"
            playbackId={playbackId}
            thumbnailTime={thumbTime}
            secondary-color="transparent"
            onCanPlay={() => setStatus((prev) => ({ ...prev, canPlay: true }))}
          />
        </div>

        {/* <Interface /> */}
      </motion.div>
      <style jsx global>{`
        mux-player {
          --center-controls: none;
          --seek-live-button: none;
          --seek-backward-button: none;
          --seek-forward-button: none;

          --fullscreen-button: none;
          --captions-button: none;
          --airplay-button: none;
          --pip-button: none;
          --cast-button: none;
          --playback-rate-button: none;
          --volume-range: none;
          --time-range: none;

          --media-object-fit: cover;
          width: 100%;
          height: 100%;
          border-radius: 0.33em;
          overflow: hidden;
          --controls-backdrop-color: rgb(0 0 0 / 10%);
        }

        .Video {
          position: relative;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .Video div {
          width: 100%;
          height: 100%;
        }

        .Video .sentinel {
          position: absolute;
          top: 0;
          left: 0;
          z-index: 2;
          height: 80%;
        }
      `}</style>
    </>
  );
};

export default Video;
