import AutoScroll from "embla-carousel-auto-scroll";
import useEmblaCarousel from "embla-carousel-react";
import { motion } from "framer-motion";
import { useRouter } from "next/router";
import React, { useCallback, useEffect, useState } from "react";

import Media from "@/components/shared/Media";

import {
  NextButton,
  PrevButton,
  usePrevNextButtons,
} from "./HomeCarouselArrowButtons";

const options = { loop: true };

const HomeCarousel = ({
  projects,
  selectedProject,
  setSelectedProject,
  focusedProject,
  setFocusedProject,
}) => {
  const router = useRouter();

  const { mode } = router?.query || {};

  const [emblaRef, emblaApi] = useEmblaCarousel(options, [
    AutoScroll({ playOnInit: true }),
  ]);

  const [isPlaying, setIsPlaying] = useState(false);

  const slides = projects;

  const {
    prevBtnDisabled,
    nextBtnDisabled,
    onPrevButtonClick,
    onNextButtonClick,
  } = usePrevNextButtons(emblaApi);

  const onButtonAutoplayClick = useCallback(
    (callback) => {
      const autoScroll = emblaApi?.plugins()?.autoScroll;
      if (!autoScroll) return;

      const resetOrStop =
        autoScroll.options.stopOnInteraction === false
          ? autoScroll.reset
          : autoScroll.stop;

      resetOrStop();
      callback();
    },
    [emblaApi],
  );

  const toggleAutoplay = useCallback(() => {
    const autoScroll = emblaApi?.plugins()?.autoScroll;
    if (!autoScroll) return;

    const playOrStop = autoScroll.isPlaying()
      ? autoScroll.stop
      : autoScroll.play;
    playOrStop();
  }, [emblaApi]);

  const startAutoplay = useCallback(() => {
    const autoScroll = emblaApi?.plugins()?.autoScroll;
    if (!autoScroll) return;

    const play = autoScroll.play;
    play();
  }, [emblaApi]);

  const stopAutoplay = useCallback(() => {
    const autoScroll = emblaApi?.plugins()?.autoScroll;
    if (!autoScroll) return;

    const stop = autoScroll.stop;
    stop();
  }, [emblaApi]);

  useEffect(() => {
    const autoScroll = emblaApi?.plugins()?.autoScroll;
    if (!autoScroll) return;

    setIsPlaying(autoScroll.isPlaying());
    emblaApi
      .on("autoScroll:play", () => setIsPlaying(true))
      .on("autoScroll:stop", () => setIsPlaying(false))
      .on("reInit", () => setIsPlaying(autoScroll.isPlaying()));
  }, [emblaApi]);

  useEffect(() => {
    if (!!focusedProject || !!selectedProject || mode === "index") {
      stopAutoplay();
      const targetIndex = slides?.findIndex(
        ({ _id }) => _id === focusedProject?._id,
      );
      targetIndex >= 0 && emblaApi?.scrollTo(targetIndex);
      console.log({ targetIndex });
    } else {
      startAutoplay();
    }
  }, [focusedProject, selectedProject, mode]);

  return (
    <>
      <motion.div
        className="HomeCarousel embla"
        animate={{
          opacity: mode !== "index" ? 1 : 0.05,
        }}
        style={{ pointerEvents: mode !== "index" ? undefined : "none" }}
      >
        <div className="embla__viewport" ref={emblaRef}>
          <div className="embla__container">
            {slides.map(({ preview, _id }, index) => (
              <div
                className="embla__slide"
                key={index}
                data-active={
                  (_id === focusedProject?._id &&
                    slides?.findIndex(
                      ({ _id }) => _id === focusedProject?._id,
                    ) === index) ||
                  !focusedProject
                }
              >
                <Media media={preview} />
              </div>
            ))}
          </div>
        </div>

        {/* <div className="embla__controls">
          <div className="embla__buttons">
            <PrevButton
              onClick={() => onButtonAutoplayClick(onPrevButtonClick)}
              disabled={prevBtnDisabled}
            />
            <NextButton
              onClick={() => onButtonAutoplayClick(onNextButtonClick)}
              disabled={nextBtnDisabled}
            />
          </div> 

          <button
            className="embla__play"
            onClick={toggleAutoplay}
            type="button"
          >
            {isPlaying ? "Stop" : "Start"}
          </button>
        </div> */}
      </motion.div>

      <style jsx global>{`
        .HomeCarousel.embla {
          position: fixed;
          bottom: var(--dist-sm);
          left: 0;
          width: 100%;
          margin: auto;
          --slide-height: calc(100 * var(--svh) / 3);
          --slide-spacing: var(--dist-xxs);
          --slide-size: auto;
          z-index: -1;
        }
        .HomeCarousel .embla__viewport {
          overflow: hidden;
        }
        .HomeCarousel .embla__container {
          backface-visibility: hidden;
          display: flex;
          touch-action: pan-y pinch-zoom;
          margin-left: calc(var(--slide-spacing) * -1);
        }
        .HomeCarousel .embla__slide {
          flex: 0 0 var(--slide-size);
          min-width: 0;
          padding-left: var(--slide-spacing);
          transition: opacity 0.4s;
        }

        .HomeCarousel .embla__slide:not([data-active="true"]) {
          opacity: 0.05;
        }

        .HomeCarousel .embla__slide .Media {
          height: var(--slide-height);
          width: auto;
        }

        .HomeCarousel .embla__controls {
        }
        .HomeCarousel .embla__buttons {
        }
        .HomeCarousel .embla__button {
        }
        .HomeCarousel .embla__button:disabled {
        }
        .HomeCarousel .embla__button__svg {
        }
        .HomeCarousel .embla__play {
        }
      `}</style>
    </>
  );
};

export default HomeCarousel;
