import "mapbox-gl/dist/mapbox-gl.css";

import { useRouter } from "next/router";
import useTranslation from "next-translate/useTranslation";
import { useEffect, useRef, useState } from "react";
import Mapbox, { Marker, Popup } from "react-map-gl";
import WebMercatorViewport from "viewport-mercator-project";

import Pin from "./Pin";

const Map = ({
  projects,
  selectedProject,
  setSelectedProject,
  focusedProject,
  setFocusedProject,
  mode,
}) => {
  const mapboxToken = process.env.NEXT_PUBLIC_MAPBOX_ACCESS_TOKEN;
  const mapStyle =
    process.env.NEXT_PUBLIC_MAP_STYLE ||
    "mapbox://styles/frauen-bauen/clxu73kdj009y01pa284e9k7u";
  const mapRef = useRef(null);

  // useEffect(() => {
  //   if (
  //     !selectedProject?.location?.lat ||
  //     !selectedProject?.location?.lng ||
  //     !mapRef?.current
  //   ) {
  //     return;
  //   }
  //   mapRef.current.flyTo({
  //     center: [selectedProject?.location?.lng, selectedProject?.location?.lat],
  //     essential: true,
  //     zoom: 13,
  //   });
  // }, [selectedProject]);

  function cleanUrl(url) {
    return url.replace(/^https?:\/\/(?:www\.)?|(\/)+$/g, "");
  }

  const pins = projects?.map((project, index) => {
    if (!project?.location?.lat || !project?.location?.lng) {
      return;
    }
    return (
      <Marker
        key={`marker-${index}`}
        longitude={project.location.lng}
        latitude={project.location.lat}
        anchor="bottom"
        onClick={(e) => {
          e.originalEvent.stopPropagation();
          mapRef.current.flyTo({
            center: [project?.location?.lng, project?.location?.lat],
            essential: true,
            zoom: 13,
          });
          setTimeout(
            () =>
              setSelectedProject(
                project?._id !== selectedProject?._id ? project : null,
              ),
            400,
          );
        }}
      >
        <div
          className="sentinel"
          onMouseEnter={() => setFocusedProject(project)}
          onMouseLeave={() => setFocusedProject(null)}
        >
          <Pin
            active={
              !!selectedProject
                ? project?._id === selectedProject?._id
                : !focusedProject || project?._id === focusedProject?._id
            }
            index={index + 1}
            project={project}
          />
        </div>
      </Marker>
    );
  });
  // Fit bounds to markers / pins
  const coordinates = pins
    ?.map((pin) => {
      if (pin?.props?.longitude && pin?.props?.latitude)
        return [pin?.props?.longitude, pin?.props?.latitude];
    })
    ?.filter(Boolean);

  const applyToArray = (func, array) => func.apply(Math, array);
  const getBounds = (markers) => {
    const lat = markers?.map((marker) => marker?.[1] ?? 0);
    const lng = markers?.map((marker) => marker?.[0] ?? 0);

    const cornersLngLat = [
      [applyToArray(Math.min, lng), applyToArray(Math.min, lat)],
      [applyToArray(Math.max, lng), applyToArray(Math.max, lat)],
    ];

    const viewport = new WebMercatorViewport({
      width: 800,
      height: 600,
    }).fitBounds(cornersLngLat, { padding: 5 });

    const { longitude, latitude, zoom } = viewport;
    return { longitude, latitude, zoom };
  };

  const bounds = getBounds(coordinates);

  const onMapLoad = () => {
    mapRef?.current?.scrollZoom?.disable();
  };

  const settings =
    mode !== "index"
      ? {
          scrollZoom: true,
          boxZoom: true,
          dragRotate: true,
          dragPan: true,
          keyboard: true,
          doubleClickZoom: true,
          touchZoomRotate: true,
          // touchPitch: true,
          // minZoom: 0,
          // maxZoom: 20,
          // minPitch: 0,
          // maxPitch: 85
        }
      : {
          scrollZoom: false,
          boxZoom: false,
          dragRotate: false,
          dragPan: false,
          keyboard: false,
          doubleClickZoom: false,
          touchZoomRotate: false,
          // touchPitch: true,
          // minZoom: 0,
          // maxZoom: 20,
          // minPitch: 0,
          // maxPitch: 85
        };

  return (
    <>
      <div
        className="Map"
        onClick={({ target }) =>
          !target?.closest(".Pin") && setFocusedProject(null)
        }
      >
        <Mapbox
          ref={mapRef}
          mapboxAccessToken={mapboxToken}
          //   cooperativeGestures={true}
          mapStyle={mapStyle}
          initialViewState={{
            latitude: bounds?.latitude,
            longitude: bounds?.longitude,
            zoom: bounds?.zoom,
          }}
          onLoad={onMapLoad}
          maxZoom={20}
          reuseMaps
          {...settings}
        >
          {pins}
        </Mapbox>
      </div>

      <style jsx global>{`
        .Map {
          width: 100%;
          height: 100vh;
          user-select: none;
          padding: var(--dist-1);
          position: relative;
        }

        .mapboxgl-popup-tip,
        .mapboxgl-popup-close-button {
          display: none;
        }
      `}</style>
    </>
  );
};
export default Map;
