import { groq } from "next-sanity";

export const refMedia = `
  video { 
    ...,
    asset->{ data, playbackId, thumbTime }
  }, 
  image {
    ...,
    asset->{ 
      ...,
      _id,
      metadata { lqip, dimensions, palette {dominant{background}} }
    }
  }
`;
