import { motion } from "framer-motion";
import { useRouter } from "next/router";
import { useState } from "react";

import HomeCarousel from "./HomeCarousel";
import HomeIndex from "./HomeIndex";
import HomeMap from "./HomeMap";
import HomeOverlay from "./HomeOverlay";

const Home = ({ projects, ...props }) => {
  console.log({ projects });

  const [focusedProject, setFocusedProject] = useState(null);
  const [selectedProject, setSelectedProject] = useState(null);

  return (
    <>
      <HomeIndex
        {...{
          projects,
          selectedProject,
          setSelectedProject,
          focusedProject,
          setFocusedProject,
        }}
      />
      <HomeMap
        {...{
          projects,
          selectedProject,
          setSelectedProject,
          focusedProject,
          setFocusedProject,
        }}
      />
      <HomeCarousel
        {...{
          projects: [...projects, ...projects, ...projects],
          selectedProject,
          setSelectedProject,
          focusedProject,
          setFocusedProject,
        }}
      />

      <HomeOverlay {...{ selectedProject, setSelectedProject }} />

      <style jsx global>{`
        .Home {
        }
      `}</style>
    </>
  );
};

export default Home;
