import { motion } from "framer-motion";
import { useRouter } from "next/router";
import { useRef } from "react";

import Blocks from "@/components/blocks/Blocks";
import useHasMounted from "@/hooks/useHasMounted";

import PageHeader from "./PageHeader";

const Page = ({ page, modal = false, refContainer, ...props }) => {
  const router = useRouter();
  const { title, header } = page || {};

  const ref = useRef();

  const hasMounted = useHasMounted();

  return (
    <>
      <motion.article
        className="Page"
        initial={{ opacity: 0 /*filter: "brightness(0) contrast(2)"*/ }}
        animate={{ opacity: 1 /*filter: "brightness(1) contrast(1)"*/ }}
        exit={{ opacity: 0 }}
        transition={{ duration: 1, type: "linear" }}
        ref={ref}
        //onAnimationComplete={handleAnimationComplete}
      >
        {hasMounted && (
          <PageHeader {...{ header, title, modal, refContainer }} />
        )}
        {hasMounted && (
          <Blocks
            header={header}
            blocks={page?.content}
            refContainer={refContainer}
          />
        )}
      </motion.article>

      <style jsx global>{`
        .Page {
          padding-bottom: var(--dist-xl);
        }
      `}</style>
    </>
  );
};

export default Page;
