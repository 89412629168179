import useWindowSize from "@/hooks/useWindowSize";
import { motion, useScroll, useTransform } from "framer-motion";
import dynamic from "next/dynamic";

// Mapping _type to component name
const typeToComponentName = (type) => {
  return type
    .split("_")
    .map((part) => part.charAt(0).toUpperCase() + part.slice(1))
    .join("");
};

// Function to dynamically import components
const importComponent = (type) => {
  const componentName = typeToComponentName(type);
  return dynamic(() => import(`./${componentName}`));
};

const Blocks = ({ blocks, header, refContainer }) => {
  const { scrollY } = useScroll({ container: refContainer });

  const { windowH } = useWindowSize();

  const opacity = useTransform(
    scrollY,
    [0, (windowH / 3) * 2, windowH + 1],
    [0, 1, 1],
  );

  return (
    <>
      <motion.article
        className="Blocks"
        style={{
          opacity:
            header?.type === "none" || header?.type === "default"
              ? undefined
              : opacity,
        }}
      >
        {blocks?.map(({ _type, ...block }, i) => {
          const Component = importComponent(_type);
          return Component && <Component key={i} block={block} />;
        })}
      </motion.article>

      <style jsx global>{`
        .Blocks {
          z-index: 3;
        }

        .Blocks > *:first-child {
          margin-top: 0;
        }

        .Blocks .grid {
          display: grid;
          align-items: start;
          grid-template-columns: var(--grid-template-columns);
          grid-gap: var(--grid-gap);
          padding: 0 var(--padding);
        }

        .Blocks td {
          padding-right: 1em;
        }

        .Blocks .large {
          font-size: var(--fs-xl);
          line-height: var(--lh-xl);
        }

        .Blocks h4 {
          margin-bottom: calc(var(--lh-sm) * 1.5);
        }

        .Blocks a {
          text-decoration: underline;
        }

        .aside {
          grid-column: 1 / 3;

          font-family: "Antique-Legacy";
          font-weigth: 450;
          font-size: calc(var(--fs-sm) / 1.2);
          line-height: var(--lh-sm);
          text-transform: uppercase;
          letter-spacing: calc((var(--fs-sm) / 1.2) * 0.12);

          td:last-child {
            text-transform: none;
            letter-spacing: normal;
            font-size: calc(var(--fs-sm));
            line-height: var(--lh-sm);
          }
        }
      `}</style>
    </>
  );
};

export default Blocks;
